/* eslint-disable no-plusplus */
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useStyles } from './styles';

const Carousel = ({ itensLength, children }) => {
  const styles = useStyles();

  const setAriaLabelOnActiveSlide = () => {
    const slideIndex = Number(document.querySelector('.slick-slide.slick-active').getAttribute('data-index')) + 1;
    document.querySelector('.slick-slide.slick-active').setAttribute('aria-label', `slide ${slideIndex}`);
  };

  const setOnlyActiveSlideButtonsAsFocusable = () => {
    const carouselSlides = document.querySelectorAll('.slick-slide');

    for (let i = 0; i < carouselSlides.length; i++) {
      const slideButtonList = carouselSlides[i].getElementsByTagName('button');

      for (let j = 0; j < slideButtonList.length; j++) {
        if (!carouselSlides[i].classList.contains('slick-active') && slideButtonList.length != 0) {
          slideButtonList[j].setAttribute('tabindex', -1);
        } else {
          slideButtonList[j].removeAttribute('tabindex');
        }
      }
    }
  };

  const focusOnActiveSlideAfterTheClick = () => {
    if (itensLength > 1) {
      const carouselDots = document.querySelectorAll('.slick-dots > ul > li');

      for (let i = 0; i < carouselDots.length; i++) {
        carouselDots[i].addEventListener('click', function() {
          setTimeout(() => document.querySelector('.slick-slide.slick-active').focus(), 1000);
        });
      }
    }
  };

  /**
   * Apply specific behaviors for a better experience for those who use screen readers
   */
  const applyAccessibilityFeatures = () => {
    setAriaLabelOnActiveSlide();
    setOnlyActiveSlideButtonsAsFocusable();
    focusOnActiveSlideAfterTheClick();
  };

  const DotsContainer = dots => (
    <div>
      <ul>{dots}</ul>
    </div>
  );

  const Dot = i => (
    <div
      className="carousel-slick-dot"
      data-testid={`carousel-dot-${i}`}
      id={`carousel-dot-${i}`}
      role="button"
      aria-label={`ir para slide ${i + 1} de ${itensLength}`}
      tabIndex="0"
    />
  );

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 10000,
    appendDots: DotsContainer,
    customPaging: Dot,
    className: styles.carousel,
    onReInit: applyAccessibilityFeatures,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    lazyLoad: true,
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default Carousel;
