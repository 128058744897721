import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) => ({
  customCheckbox: {
    position: 'relative',
    top: 0,
    left: 0,

    height: '1.5rem',
    width: '1.5rem',

    backgroundColor: props => (props.checked ? palette.primary.main : palette.white),
    color: '#fff',

    borderRadius: '5px',
    border: 'solid 1px',
    borderColor: 'black',

    marginRight: '0.5rem',
    gridRow: 1,
    gridColumn: 1,
  },
  containerLabel: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '2rem 4fr',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  checkmark: {
    content: '',
    position: 'absolute',

    width: '0.35rem',
    height: '0.75rem',

    left: '0.5rem',
    top: '0.25rem',
    border: 'solid',
    borderWidth: ' 0 0.125rem 0.125rem 0',
    borderColor: 'white',
    transform: 'rotate(50deg)',
  },
  hiddenDefaultCheckbox: {
    position: 'absolute',
    opacity: 0,
    height: 0,
    width: 0,
  },
  labelStyles: {
    fontSize: '0.875rem',
    color: palette.inputLabelColor,
    fontFamily: 'ItauText-Regular',
    lineHeight: '1rem',
    gridRow: 1,
    gridColumn: 2,
  },
  wrapperDiv: {
    position: 'relative',
    height: 'fit-content',
    width: 'fit-content',
    margin: '1rem 0',
  },
  errorMessage: {
    color: palette.error.main,
    fontSize: '0.85rem',
    marginTop: '0.25rem',
  },
}));
