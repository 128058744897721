import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, constants }) => ({
  root: {
    backgroundColor: props => props.backgroundColor,
    padding: `2rem ${constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN}`,

    [breakpoints.up('xl')]: {
      padding: `2rem ${constants.DEFAULT_DESKTOP_HORIZONTAL_MARGIN}`,
    },
  },
}));
