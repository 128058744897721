import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Switch from 'components/Switch';
import { makeId } from 'utils/util';
import { useStyles } from './styles';

export default function HookSwitchField({ name, errorMessage, ...props }) {
  const { errors } = useFormContext();
  const styles = useStyles();

  const errorId = `switchField-${name || makeId(8)}-error`;
  const dynamicProps = {};
  if (errors[name] && errorMessage) dynamicProps['aria-describedby'] = errorId;

  return (
    <>
      <Controller
        render={({ value, ref, onChange }) => (
          <Switch name={name} inputRef={ref} onChange={onChange} checked={value || false} {...props} />
        )}
        name={name}
        error={!!errors[name]}
        {...dynamicProps}
        {...props}
      />
      {errors[name] && (
        <span id={errorId} role="alert" className={styles.errorMessage}>
          {errorMessage}
        </span>
      )}
    </>
  );
}
