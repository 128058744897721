import React from 'react';
import StringField from 'shared/fields/stringField';
import MaskedField from 'shared/fields/maskedField';
import ArrayField from 'shared/fields/arrayField';
import BooleanField from 'shared/fields/booleanField';

import CheckGroupField from './items/CheckGroupFieldRHFController';
import StringInputField from './items/StringField';
import MaskedInputField from './items/MaskedField';
import BooleanInputField from './items/BooleanFieldRHFController';

import SwitchInputField from './items/SwitchField';

const HookFormItem = ({ field, customRef, ...props }) => {
  if (field instanceof StringField)
    return (
      <StringInputField
        name={field.name}
        label={field.label}
        customRef={customRef}
        errorMessage={field.requiredMessage}
        {...field}
        {...props}
      />
    );

  if (field instanceof MaskedField) {
    return (
      <MaskedInputField
        name={field.name}
        label={field.label}
        mask={field.mask}
        required={field.required}
        {...props}
        {...field}
      />
    );
  }

  if (field instanceof ArrayField)
    return (
      <CheckGroupField
        name={field.name}
        label={field.label}
        options={field.options}
        errorMessage={field.requiredMessage}
        {...props}
      />
    );

  if (field instanceof BooleanField)
    switch (field.style) {
      case 'switch':
        return (
          <SwitchInputField
            name={field.name}
            label={field.label}
            ariaLabel={field.ariaLabel}
            errorMessage={field.requiredMessage}
            yesLabel={field.yesLabel}
            noLabel={field.noLabel}
          />
        );
      default:
        return <BooleanInputField name={field.name} label={field.label} errorMessage={field.requiredMessage} />;
    }
};

export default HookFormItem;
