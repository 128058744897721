import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, constants }) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: palette.secondary.main,

    [breakpoints.up('lg')]: {
      minHeight: '30rem',
      flexDirection: 'row',
      margin: 0,
    },
  },
  headerTextualSide: {
    display: 'none',
    [breakpoints.up('lg')]: {
      width: '40%',
      order: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginRight: constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN,
    },
  },
  headerTextualMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN,
    order: 2,
    [breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  headerTextualMobileCashback: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN,
    order: 2,
    [breakpoints.up('lg')]: {
      display: 'none',
    },
    paddingTop: '30px'
  },
  headerImagesSide: {
    display: 'flex',
    justifyContent: 'center',
    position: 'static',
    width: '100%',
    order: 1,

    [breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      width: '60%',
      position: 'relative',
      order: 2,
    },
  },
  headerImagesContainer: {
    position: 'static',
    paddingRight: constants.DEFAULT_DESKTOP_HORIZONTAL_MARGIN,
    width: '100%',
    height: '83vw',
    minHeight: '33vh',
    maxHeight: '551px',

    [breakpoints.up('lg')]: {
      position: 'absolute',
      height: 'calc(100% + 4rem)',
    },
  },
  headerTitle: {
    margin: '1rem 0 0 0',
    borderBottom: `4px solid ${palette.primary.main}`,
    fontFamily: 'ItauText-Regular',
    fontSize: '1.56rem',
    width: 'fit-content',
    lineHeight: '3.125rem',
    paddingLeft: constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN,

    [breakpoints.up('xl')]: {
      paddingLeft: constants.DEFAULT_DESKTOP_HORIZONTAL_MARGIN,
    },
  },
  headerSubtitle: {
    margin: '2.5rem 0',
    fontFamily: 'ItauText-Regular',
    fontSize: props => (props.bigSubtitle ? '2.5rem' : '1.375rem'),
    lineHeight: props => (props.bigSubtitle ? '3.125rem' : '1.7rem'),
    paddingLeft: constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN,

    [breakpoints.up('500')]: {
      marginTop: '14rem',
    },

    [breakpoints.up('lg')]: {
      margin: '4rem 0 1rem',
      lineHeight: '3.125rem !important',
      fontSize: '2.5rem !important',
      paddingLeft: constants.DEFAULT_DESKTOP_HORIZONTAL_MARGIN,
    },
  },
  headerAuxText: {
    margin: '0',
    fontFamily: 'ItauText-Regular',
    fontSize: '1.375rem',
    lineHeight: '1.7rem',
    paddingLeft: constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN,

      [breakpoints.up('lg')]: {
      paddingLeft: constants.DEFAULT_DESKTOP_HORIZONTAL_MARGIN,
    },
  },
  content: {
    backgroundColor: palette.homeBody,
  },
  callToAction: {
    margin: 0,
    paddingLeft: constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN,
    paddingRight: constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN,

    [breakpoints.up('xl')]: {
      paddingLeft: constants.DEFAULT_DESKTOP_HORIZONTAL_MARGIN,
      paddingRight: constants.DEFAULT_DESKTOP_HORIZONTAL_MARGIN,
    },
  },
  callToActionText: {
    color: palette.text.main,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    paddingTop: '2rem',
    paddingBottom: '1rem',
  },
  link: {
    display: 'block',
    width: '100%',
    textAlign: 'right',
    position: 'relative',
    color: 'black',
    fontWeight: 'bold',
    [breakpoints.down('md')]: {
      fontSize: '14px',
      textAlign: 'center',
    },
  },
}));
