import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStyles } from './stringField.styles';
import TextInputField from '../../../form/items/TextInputField/TextInputField';

const StringField = ({ name, label, errorMessage, customRef, ...props }) => {
  const { errors } = useFormContext();

  const styles = useStyles();

  const errorId = `stringField-${name}-error`;

  return (
    <div>
      <Controller
        as={TextInputField}
        name={name}
        error={!!errors[name]}
        aria-describedby={errorId}
        label={label}
        inputRef={customRef}
        {...props}
      />
      {errors[name] && (
        <span id={errorId} role="alert" className={styles.errorMessage}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default StringField;
