import React, { useState } from 'react';
import { useStyles } from './Checkbox.styles';

const Checkbox = ({ id, option, onChange, selected, ...props }) => {
  const optionValue = option.tag || option.value;
  const [isSelected, setIsSelected] = useState(selected);
  const styles = useStyles({ checked: isSelected });

  const labelId = `option-${option.label.replace(/ /g, '-')}`;

  return (
    <div className={styles.wrapperDiv}>
      <label htmlFor={id} className={styles.containerLabel}>
        <input
          type="checkbox"
          id={id}
          value={optionValue}
          checked={isSelected}
          aria-labelledby={labelId}
          onChange={e => {
            if (typeof onChange === 'function') onChange(e.target.value);
            setIsSelected(!isSelected);
          }}
          className={styles.hiddenDefaultCheckbox}
          {...props}
        />
        <div className={styles.customCheckbox}>{isSelected && <div className={styles.checkmark} />}</div>
        <span
          className={styles.labelStyles}
          id={labelId}
          aria-label={`${option.label} ${option.complementaryInfo ? option.complementaryInfo : ''}`}
        >
          {option.label}
        </span>
        {option.complementaryInfo && (
          <span className={styles.complementaryInfo} aria-hidden>
            {option.complementaryInfo}
          </span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
