import StringField from 'shared/fields/stringField';
import MaskedField from 'shared/fields/maskedField';
import ArrayField from 'shared/fields/arrayField';
import FormSchema from 'shared/form/formSchema';
import { cpfModel } from '../../../shared/form/commonModels';
import { getPureChannel } from '../../../utils/channels';
import config from '../../../config';

const createFields = (enableCellphoneMask, products) => {
  const currentChannel = getPureChannel();
  const filteredProductsByChannel = products.filter(
    product => !product['forbidden-channels']?.includes(currentChannel),
  );
  const dynamicChecksOptions = filteredProductsByChannel.map(product => {
    let isProductEnabled = product.enableE2E;
    const { AUTOLEAD, RESIDLEAD } = config;
    if (product.name === 'Auto' && AUTOLEAD) isProductEnabled = false;
    if (product.name === 'Residencial' && RESIDLEAD) isProductEnabled = false;

    return {
      value: product.id,
      label: product.label?.toLowerCase(),
      complementaryInfo: isProductEnabled ? 'já disponível online' : undefined,
      path: product.path,
    };
  });

  return {
    name: new StringField('name', 'como podemos te chamar?', {
      required: true,
      requiredMessage: 'você precisa informar como gostaria de ser chamado',
    }),
    cpf: cpfModel({
      name: 'cpf',
      label: 'qual o seu CPF?',
      required: true,
      requiredMessage: 'você precisa informar o seu CPF',
      invalidMessage: 'você precisa informar um CPF válido',
    }),
    email: new StringField('email', 'pode me passar o e-mail para enviar a cotação depois?', {
      required: true,
      requiredMessage: 'você precisa informar o seu e-mail',
      type: 'email',
      matches: {
        pattern: /[a-z0-9_-]+(?:\.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      },
    }),
    telephone: new MaskedField(
      'tel',
      'em qual número podemos te ligar?',
      enableCellphoneMask ? '(99) 99999-9999' : '(99) 9999-9999',
      {
        required: true,
        requiredMessage: 'você precisa informar número para contato',
        type: 'tel',
        matches: {
          pattern: /^(?:\(?([1-9][1-9])\)?\s?)?(?:((?:[9]\d|[2-5])\d{3})-?(\d{4}))$/,
        },
      },
    ),
    checks: new ArrayField('interestedProducts', 'quais os produtos do seu interesse?', {
      options: dynamicChecksOptions,
      required: true,
      requiredMessage: 'você precisa selecionar um ou mais produtos',
      min: 1,
    }),
  };
};

const createSchema = (fields, oneProduct) => {
  if (oneProduct) {
    return new FormSchema(fields.id, [fields.name, fields.cpf, fields.telephone, fields.email]);
  }
  return new FormSchema(fields.id, [fields.name, fields.cpf, fields.telephone, fields.email, fields.checks]);
};

export { createFields, createSchema };
