import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const HookFormUnit = ({ mode, reValidateMode, defaultValues, schema, onSubmit, debug, children }) => {
  const methods = useForm({
    mode,
    reValidateMode,
    defaultValues,
    resolver: yupResolver(schema),
  });

  const fieldWatch = methods.watch();

  const renderDebug = () => (
    <div
      style={{
        background: '#f6f8fa',
        fontSize: '.75rem',
        padding: '.5rem',
      }}
    >
      <strong>Field's content: </strong>
      <pre>{JSON.stringify(fieldWatch, null, 2)}</pre>
      <strong>FormState: </strong>
      <pre>{JSON.stringify(methods.formState, null, 2)}</pre>
    </div>
  );

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          {debug && renderDebug()}
          {children}
        </form>
      </FormProvider>
    </>
  );
};

export default HookFormUnit;
