import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CheckGroupField from '../../../commonFields/CheckGroupField';

const ControllerCheckGroup = ({ name, options, label, errorMessage }) => {
  const { errors } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ onChange, onBlur, value }) => (
        <CheckGroupField
          name={name}
          options={options}
          initialValue={value}
          label={label}
          error={!!errors[name]}
          errorMessage={errorMessage}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default ControllerCheckGroup;
