const CONTACT = {
  HEADER_TEXT:
    'se tiver dúvidas sobre o que é melhor pra você, deixe seus dados que ligaremos pra te ajudar',
  FORM_CONTAINER_TITLE: 'selecione os produtos de seu interesse: *',
  FORM_CONTAINER_TITLE_ARIA_LABEL:
    'selecione os produtos de seu interesse obrigatório',
  FORM_CHECKBOX_TITLE_DESKTOP: 'Produtos disponíveis:',
  BUTTON_TEXT: 'enviar dados',
  SUCCESS_MESSAGE:
    'Para a segurança da nossa operação, devido ao coronavírus (COVID-19) estamos trabalhando com o quadro reduzido, mas fique tranquilo que logo entraremos em contato!',
  SUCCESS_SUBTITLE:
    'Enquanto isso, <a href="/assessoria">clique aqui</a> e descubra quais são os seguros e assistências mais recomendadas para você',
};

export default CONTACT;
