import { useEffect, useState } from 'react';
import { RecaptchaContext } from 'providers/RecaptchaProvider';
import { Grid, makeStyles } from '@material-ui/core';
import { getChannel, getChannelIfIsBlokedForLead } from 'utils/channels';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button/Button';
import CONTACT from '../../../resources/Home/Contact/contact.resource';
import SNACKBAR_TYPES from '../../../utils/snackbar.types';
import HookFormUnit from '../../../shared/hookForm/HookFormUnit';
import { createFields, createSchema } from './ContactForm.model';
import { create } from '../../../store/ducks/lead';
import HookFormItem from '../../../shared/hookForm/HookFormItem';
import config from '../../../config';
import { leadSelector } from '../../../store/sagas/selectors/lead';

const { ADVISORY } = config;

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    width: '100%',
    height: 'fitContent',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonInnerBox: {
    padding: '0 2rem !important',
  },
  SectionTitle: {
    fontSize: '1.625rem',
    lineHeight: '2.25rem',
    textAlign: 'left',

    margin: '0 0 1rem 0',

    [breakpoints.up('md')]: {
      maxWidth: '70%',
    },
  },
  SectionSubTitle: {
    fontFamily: 'ItauText-Regular',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    textAlign: 'left',

    [breakpoints.up('md')]: {
      maxWidth: '70%',
    },
  },
}));

const ContactForm = ({
  products,
  page,
  oneProduct,
  customTitle,
  customSubtitle,
  firstRef,
  handleSendDataDispatchAnalytics,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const lead = useSelector(leadSelector);

  const [fields, setFields] = useState(createFields(false, products));
  const [schema, setSchema] = useState(createSchema(fields, oneProduct));

  const [enableCellphoneMask, setEnableCellphoneMask] = useState(false);

  useEffect(() => {
    setFields(createFields(enableCellphoneMask, products));
  }, [enableCellphoneMask]);

  useEffect(() => {
    setSchema(createSchema(fields, oneProduct));
  }, [fields]);

  const parseClientInfo = formInfo => {
    const { cpf, email, name, tel } = formInfo;
    return {
      nome: name,
      email,
      telefone: tel.replace(/[.() -]/g, ''),
      cpf: cpf.replace(/[.-]/g, ''),
    };
  };

  const parseProductInfo = formInfo => {
    const { interestedProducts } = formInfo;
    let filteredProducts;
    if (oneProduct) {
      filteredProducts = products;
    } else {
      filteredProducts = products.filter(item => interestedProducts.includes(item.id));
    }
    return filteredProducts.map(item => ({ codigo: item.id, nome: item.name }));
  };

  const generatePayload = formInfo => {
    const payload = {
      canal: getChannelIfIsBlokedForLead() || getChannel(),
      cliente: parseClientInfo(formInfo),
      produtos: parseProductInfo(formInfo),
      indicador_termo_autorizacao: true,
    };

    return payload;
  };

  const onSubmit = async (values, getToken) => {
    const { SUCCESS_MESSAGE, SUCCESS_SUBTITLE } = CONTACT;

    const successMessage = {
      message: SUCCESS_MESSAGE,
      onCloseCallback: null,
      refFocus: `#btnContactSubmit_screen${page}`,
      type: SNACKBAR_TYPES.SUCCESS,
    };

    const errorMessage = {
      onCloseCallback: null,
      refFocus: `#btnContactSubmit_screen${page}`,
      type: SNACKBAR_TYPES.ERROR,
    };

    const token = await getToken('leads_home');
    const payload = generatePayload(values);
    const productsNames = payload.produtos.map(product => product.nome).join(',');

    if (ADVISORY.enabled) {
      successMessage.subtitle = SUCCESS_SUBTITLE.replace('#NAME', payload.cliente.nome).replace('#PRODUCTS', products);
    }

    if (handleSendDataDispatchAnalytics && typeof handleSendDataDispatchAnalytics === 'function')
      handleSendDataDispatchAnalytics(productsNames);
    dispatch(create(payload, null, false, successMessage, errorMessage, token));
  };

  /**
   * This function serves the task of determinating when to switch telephone fields's mask
   * from cellphone to house phone and v.v. depending on field value length and pressed keys
   * @param {SyntheticEvent} event
   * @param {object} fieldFunctions (Injected in MaskedField) React Hook Form field management functions(setValue, and getValue)
   */
  const telephoneKeyDown = (event, fieldFunctions) => {
    const pressedKey = { keyValue: event.key, keyId: event.keyCode };
    const BACKSPACE = 8;
    const DELETE = 46;
    const keyIsNumber =
      (pressedKey.keyId >= 48 && pressedKey.keyId <= 57) || (pressedKey.keyId >= 96 && pressedKey.keyId <= 105);
    const fieldLength = event.target.value.length;

    const eraseDigit = (str, index, keyType) => {
      if (keyType === DELETE) return str.slice(0, index + 1) + str.slice(index);
      return str.slice(0, index - 1) + str.slice(index);
    };

    if ((pressedKey.keyId === BACKSPACE || pressedKey.keyId === DELETE) && fieldLength === 15) {
      event.preventDefault();
      // replaces prior outdated mask with new mask value format
      const treatedValue = eraseDigit(event.target.value, event.target.selectionStart)
        .replace(/[() -]+/g, '')
        .replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3');
      setEnableCellphoneMask(false);
      // only reValidate field if form was already submitted
      fieldFunctions.setValue(treatedValue, fieldFunctions.formState.isSubmitted);
    }

    if (keyIsNumber && fieldLength === 14) {
      // replaces prior outdated mask with new mask value format
      const fieldContent = event.target.value.replace(/[() -]+/g, '').replace(/^(\d{2})(\d{5})(\d{3}).*/, '($1) $2-$3');
      setEnableCellphoneMask(true);
      // only reValidate field if form was already submitted
      fieldFunctions.setValue(fieldContent + pressedKey.keyValue, fieldFunctions.formState.isSubmitted);
    }
  };

  return (
    <RecaptchaContext.Consumer>
      {({ getToken }) => (
        <Grid container xs={12}>
          <Grid item md={12} lg={6}>
            <h2 className={styles.SectionTitle}>
              {customTitle || 'Quer falar com um consultor? Deixa seus dados aqui que ligamos para te ajudar!'}
            </h2>
            <h3 className={styles.SectionSubTitle}>
              {customSubtitle || (
                <span>
                  A ideia é te auxiliar na <strong>escolha do melhor produto</strong>! Preencha os dados que um corretor
                  vai entrar em contato com você
                </span>
              )}
            </h3>
          </Grid>
          <Grid item md={12} lg={6}>
            <div className={styles.container}>
              <HookFormUnit
                mode="onSubmit"
                reValidateMode="onChange"
                schema={schema.schema}
                onSubmit={values => onSubmit(values, getToken)}
              >
                {!oneProduct && <HookFormItem field={fields.checks} />}
                <HookFormItem field={fields.name} customRef={firstRef} />
                <HookFormItem field={fields.cpf} />
                <HookFormItem field={fields.email} />
                <HookFormItem field={fields.telephone} onKeyDown={telephoneKeyDown} />
                <p>
                  Os dados inseridos por você poderão ser compartilhados com empresas parceiras para cotação e
                  contratação dos produtos escolhidos e poderão ser tratados de acordo com nossa Política de Privacidade
                  disponível em nossos sites e aplicativos
                </p>
                <Button
                  customId="btncontact_screenform"
                  type="submit"
                  text="enviar dados"
                  context="contact"
                  page="form"
                  index={0}
                  disabled={lead.isCreating}
                  loading={lead.isCreating}
                  className={styles.buttonInnerBox}
                />
              </HookFormUnit>
            </div>
          </Grid>
        </Grid>
      )}
    </RecaptchaContext.Consumer>
  );
};

export default ContactForm;
