import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles.js';

export default function Section({ backgroundColor = 'inherit', className, children, ...props }) {
  const styles = useStyles({ backgroundColor });
  return (
    <article className={clsx(styles.root, className)} data-testid="structural-section" {...props}>
      {children}
    </article>
  );
}
