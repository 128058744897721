import PropTypes from 'prop-types';
import Markdown from 'components/Markdown';
import Loading from 'components/Loading/Loading';
import { Box } from '@material-ui/core';
import { useCashbackProviderConsumer } from 'providers/CashbackProvider';
import CrossfadeImages from '../CrossfadeImages';
import { useStyles } from './styles';
import { markdownToString } from 'utils/util';

export default function Container({
  title,
  subtitle,
  partners,
  asideItems,
  bigSubtitle,
  callToAction,
  callToActionRef,
  loading = false,
  children,
  idSubtitle,
  enablePartnersMobile,
  campaignRules,
  campaignDate,
  auxText,
  auxTextLabel,
  ...props
}) {
  const styles = useStyles({ bigSubtitle });
  const { cashbackConfig, cashbackIsEnabled } = useCashbackProviderConsumer();
  function GetAppropriateSubtitleElement({ children: subtitleChildren, idSubTitle }) {
    if (title)
      return (
        <h2
          id={idSubTitle}
          className={styles.headerSubtitle}
          data-testid="structural-container-subtitle"
          aria-label={markdownToString(subtitle)}
        >
          <span aria-hidden>{subtitleChildren}</span>
        </h2>
      );
    return (
      <h1
        id={idSubTitle}
        className={styles.headerSubtitle}
        data-testid="structural-container-subtitle"
        aria-label={markdownToString(subtitle)}
      >
        <span aria-hidden>{subtitleChildren}</span>
      </h1>
    );
  }

  function CallToAction() {
    switch (typeof callToAction) {
      case 'string':
        return (
          <h2
            className={styles.callToActionText}
            ref={callToActionRef}
            tabIndex={-1}
            data-testid="structural-container-call-to-action"
          >
            {callToAction}
          </h2>
        );
      case 'function':
        return callToAction();
      case 'object':
        return callToAction;
      default:
        return null;
    }
  }

  if (loading) return <Loading />;
  return (
    <div data-testid="structural-container" {...props}>
      <section className={styles.header}>
        <div className={styles.headerTextualSide}>
          {title && (
            <h1 className={styles.headerTitle} data-testid="structural-container-title">
              <Markdown source={title} />
            </h1>
          )}
          <GetAppropriateSubtitleElement idSubTitle={idSubtitle}>
            <Markdown source={subtitle} />
            {partners && <Box>{partners}</Box>}
          </GetAppropriateSubtitleElement>
          {auxText && (
            <span aria-label={auxTextLabel}>
              <p
                className={styles.headerAuxText}
                data-testid="structural-container-auxText"
                aria-hidden={!!auxTextLabel}
              >
                <Markdown source={auxText} />
              </p>
            </span>
          )}
        </div>
        <div className={styles.headerImagesSide} role="region">
          {asideItems && (
            <div className={styles.headerImagesContainer}>
              
              <CrossfadeImages componentIn={asideItems} />
             
              {cashbackIsEnabled(window.location.pathname) && (
                <a
                  href={cashbackConfig?.terms_of_use?.path}
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cashbackConfig?.terms_of_use_title ?? 'confira o regulamento da promoção de cashback'}
                </a>
              )}    
              {!!campaignRules?.href && !!campaignRules?.title && (
           
                <a 
                  href={campaignRules?.href}
                  className={styles.link}
                  target="_blank"
                  data-testid="campaign-rules-link"
                  rel="noopener noreferrer"
                >
                  {campaignRules?.title}
                </a>
              )}
            </div>
          )}
        </div>
        <div
          className={
            cashbackIsEnabled(window.location.pathname)
              ? styles.headerTextualMobileCashback
              : styles.headerTextualMobile
          }
        >
          {title && (
            <h1 className={styles.headerTitle} data-testid="structural-container-title">
              <Markdown source={title} />
            </h1>
          )}
          <GetAppropriateSubtitleElement idSubTitle={idSubtitle}>
            <Markdown source={subtitle} />
            {enablePartnersMobile && partners && <Box>{partners}</Box>}
          </GetAppropriateSubtitleElement>
          {auxText && (
            <div aria-label={auxTextLabel}>
              <p
                className={styles.headerAuxText}
                data-testid="structural-container-auxText"
                aria-hidden={!!auxTextLabel}
              >
                <Markdown source={auxText} />
              </p>
            </div>
          )}
        </div>
      </section>
      <section className={styles.content}>
        <div className={styles.callToAction}>
          <CallToAction />
        </div>
        <div data-testid="structural-container-children">{children}</div>
      </section>
    </div>
  );
}

Container.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  asideItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  bigSubtitle: PropTypes.bool,
  callToAction: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  callToActionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  children: PropTypes.node,
  idSubtitle: PropTypes.string,
  campaignDate: PropTypes.string,
  campaignRules: PropTypes.shape({
    href: PropTypes.string,
    title: PropTypes.string
  }),
  auxText: PropTypes.string,
  subtitleLabel: PropTypes.string,
  auxTextLabel: PropTypes.string
};

Container.defaultProps = {
  bigSubtitle: false
};
