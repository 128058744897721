import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInputField from '../../../../components/MaskInputField/MaskInputField';
import { useStyles } from './maskedField.styles';

const MaskField = ({
  name,
  label,
  mask,
  ariaLabel,
  required,
  errorMessage,
  inputRef,
  onKeyUp,
  onKeyDown,
  ...props
}) => {
  const { errors, setValue, getValues, formState } = useFormContext();
  const styles = useStyles();

  const fieldFunctions = {
    setValue: (valueToSet, shouldValidate) => {
      setValue(name, valueToSet, { shouldValidate });
    },
    getValues: () => getValues(name),
    formState,
  };

  const keyDown = e => {
    if (onKeyDown) onKeyDown(e, fieldFunctions);
  };

  const keyUp = e => {
    if (onKeyUp) onKeyUp(e, fieldFunctions);
  };

  const errorId = `maskedField-${name}-error`;

  return (
    <div>
      <Controller
        name={name}
        render={({ onChange, onBlur, value }) => (
          <MaskInputField
            error={!!errors[name]}
            label={label}
            name={name}
            aria-describedby={errorId}
            required={required}
            mask={mask}
            value={value}
            inputRef={inputRef}
            ariaLabel={ariaLabel}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={keyDown}
            onKeyUp={keyUp}
            {...props}
          />
        )}
      />
      {errors[name] && (
        <span className={styles.errorMessage} id={errorId} role="alert">
          {errors[name].message}
        </span>
      )}
    </div>
  );
};

export default MaskField;
