import React from 'react';
import './crossfadeKeyframes.scss';
import { useStyles } from './styles';
import Carousel from '../Carousel';

export default function CrossfadeImages({ componentIn, delay = 5000 }) {
  const styles = useStyles({ imagesLength: componentIn.length, delay });

  if (Array.isArray(componentIn)) {
    return (
      <div className={styles.root} data-testid="crossfade-images">
        <Carousel itensLength={componentIn.length}>
          {componentIn.map((component, index) => {
            if (typeof component === 'string')
              return (
                <img
                  key={index}
                  className={styles.image}
                  src={component}
                  alt="Imagem de cabeçalho"
                  data-testid={`crossfade-image-${index}`}
                />
              );
            if (typeof component === 'object' && component.dataType === 'simpleObject')
              return (
                <img
                  key={index}
                  className={styles.image}
                  src={component.src}
                  alt={component.alt}
                  data-testid={`crossfade-image-${index}`}
                />
              );
            return <div className={styles.component}>{component}</div>;
          })}
        </Carousel>
      </div>
    );
  }

  return componentIn;
}
