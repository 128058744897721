import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, toRem }) => ({
  carousel: {
    width: '100%',
    height: '100%',
    '& .slick-list': {
      width: '100%',
      height: '100%',
    },
    '& .slick-track': {
      width: '100%',
      height: '100%',
    },
    '& .slick-slide > div': {
      height: '100%',
      '& > div': {
        display: 'block!important',
      },
    },
    '& .slick-active > .carousel-slick-dot': {
      backgroundColor: palette.primary.main,
    },
    '& .slick-dots': {
      position: 'initial',
      '& ul': {
        padding: 0,
      },
      [breakpoints.up('lg')]: {
        position: 'absolute',
        bottom: 0,
      },
    },
    '& .carousel-slick-dot': {
      cursor: 'pointer',
      height: toRem(15),
      width: toRem(15),
      borderRadius: '50%',
      display: 'inline-block',
      transition: 'background-color 0.5s ease',
      border: `${toRem(2)} #D6D0CC solid`,
      boxShadow: '0px 0px 2px 1px rgb(0 0 0 / 30%)',
    },
  },
}));
