import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) => ({
  styledLabel: {
    color: '#605751',
    fontFamily: 'ItauText-Regular',
    marginTop: 0,
    marginBottom: '1rem',
    fontSize: '0.875rem',
    lineHeight: '1rem',
    textAlign: 'left',
  },
  wrapperDiv: {
    marginBottom: '0.5rem',
  },
  errorMessage: {
    color: palette.error.main,
    fontSize: '0.85rem',
    marginTop: '0.25rem',
  },
}));
