import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useStyles } from './CheckGroupField.styles';
import Checkbox from './CheckBox';

const CheckGroupField = ({ name, options, label, error, errorMessage, onChange, initialValue }) => {
  const styles = useStyles();
  const value = initialValue ? [...initialValue] : [];
  const [groupSelected, setGroupSelected] = useState(value);

  const handleSelection = optValue => {
    const optIndex = groupSelected.indexOf(optValue);

    if (optIndex === -1) {
      const newValue = [...groupSelected, optValue];
      setGroupSelected(newValue);
      onChange(newValue);
    } else {
      const newValue = [...groupSelected.slice(0, optIndex), ...groupSelected.slice(optIndex + 1)];
      setGroupSelected(newValue);
      onChange(newValue);
    }
  };

  const labelId = `check-${name}-label`;

  return (
    <div className={styles.wrapperDiv}>
      <p className={styles.styledLabel} id={labelId}>
        {label}
      </p>
      <Grid container xs={12} spacing={3} alignItems="flex-start">
        {options.map((option, index) => (
          <Grid item>
            <Box pr={2}>
              <Checkbox
                key={index}
                name={`${name}`}
                id={`check-${name}-${index}`}
                option={option}
                onChange={optionValue => handleSelection(optionValue)}
                selected={groupSelected.includes(option.value)}
                aria-describedby={labelId}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      {error && (
        <span className={styles.errorMessage} role="alert">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default CheckGroupField;
