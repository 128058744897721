import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import BooleanField from '../../../commonFields/BooleanField';

function BooleanFieldController({ name, errorMessage, label }) {
  const { errors } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ onChange, value }) => (
        <BooleanField
          name={name}
          label={label}
          onChange={onChange}
          selected={value}
          error={!!errors[name]}
          errorMessage={errorMessage}
        />
      )}
    />
  );
}

export default BooleanFieldController;
