import React, { useState } from 'react';
import { useStyles } from './booleanField.styles';

const BooleanField = ({ name, label, onChange, selected, error, errorMessage, ...props }) => {
  const [isSelected, setIsSelected] = useState(selected);
  const styles = useStyles({ checked: isSelected });

  const inputId = `input-booleanField-${name}`;
  const labelId = `label-booleanField-${name}`;

  const handleSelect = () => {
    const newValue = !isSelected;
    onChange(newValue);
    setIsSelected(newValue);
  };

  return (
    <div className={styles.wrapperDiv}>
      <label htmlFor={inputId} className={styles.containerLabel}>
        <span className={styles.labelStyles} id={labelId}>
          {label}
        </span>
        <input
          type="checkbox"
          id={inputId}
          value={isSelected}
          name={name}
          checked={isSelected}
          aria-labelledby={labelId}
          aria-invalid={error}
          onChange={handleSelect}
          className={styles.hiddenDefaultCheckbox}
          {...props}
        />
        <div className={styles.customCheckbox}>{isSelected && <div className={styles.checkmark} />}</div>
      </label>
      {error && (
        <span className={styles.errorMessage} role="alert">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default BooleanField;
