import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { markdownToString } from 'utils/util';
import Markdown from '../../Markdown';
import { useStyles } from './styles.js';

const Title = ({ text, id, subtitle, className, alignment, tabIndex, ...props }) => {
  const styles = useStyles({ alignment });

  const dynamicProps = {};
  if (id) dynamicProps.id = id;
  if (tabIndex) dynamicProps.tabIndex = tabIndex;

  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <h2 className={styles.title} aria-label={markdownToString(text)} {...dynamicProps}>
        <span aria-hidden="true">
          <Markdown source={text} />
        </span>
      </h2>
      <div className={styles.lineSeparator} data-testid="separator" />
      {subtitle ? (
        <p className={styles.subtitle} aria-label={markdownToString(subtitle)}>
          <Markdown source={subtitle} />
        </p>
      ) : null}
    </div>
  );
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subtitle: PropTypes.string,
  className: PropTypes.string,
  alignment: PropTypes.oneOf(['start', 'end', 'center']),
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Title;
