import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: props => (props.alignment ? props.alignment : 'center'),
    marginBottom: '2rem',
  },
  title: {
    fontFamily: 'ItauText-Light',
    fontSize: '2rem',
    margin: 0,
    marginTop: '2.5rem',
    textAlign: props => (props.alignment ? props.alignment : 'center'),
    alignSelf: props => (props.alignment ? props.alignment : 'center'),
    '& strong': {
      fontFamily: 'ItauText-Bold',
    },
  },
  lineSeparator: {
    backgroundImage: 'linear-gradient(135deg, #E66100 0%, #FEA100 100%)',
    width: '5rem',
    height: '0.2rem',
    margin: '.5rem 0',
  },
  subtitle: {
    fontFamily: 'ItauText-Regular',
    fontSize: '1.125rem',
    textAlign: props => (props.alignment ? props.alignment : 'center'),
    margin: '1rem 0 0 0',
  },
}));
